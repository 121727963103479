import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { titles, descriptions } from '../../const/metaData';
import { Wrapper, TextContainer, BoldText } from '../../styles/commonStyles';
import { paths } from '../../const/paths';
import { Link } from 'gatsby';

const WebsiteTerms: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO
        title={titles.websiteTerms}
        description={descriptions.websiteTerms}
      />
      <Wrapper>
        <TextContainer>
          <h2>Terms and Conditions of Use of the Bó Website</h2>
          <BoldText>1. WHO ARE WE?</BoldText>
          <p>
            This website is owned and maintained by us (Bó, part of the National
            Westminster Bank (“NatWest”), which is registered in England and
            Wales (Registered Number 929027) and has its registered office at
            250 Bishopsgate, London EC2M 4AA).
          </p>
          <BoldText>2. USING OUR WEBSITE</BoldText>
          <p>
            Please read these Terms carefully as they govern the use of our
            website. By visiting our website, you are agreeing to be bound by
            these Terms and our Privacy Policy which can be found{' '}
            <Link to={paths.privacyFull}>here</Link>.
          </p>
          <p>
            All the products and services we provide have their own terms. If
            you’re a Bó customer, you must read those terms as well as these
            Terms. If these Terms are inconsistent with any of our other product
            or service terms then those terms will apply.
          </p>
          <p>
            Our website is designed for use by UK residents in the UK. If you
            choose to access this website from outside the UK this is at your
            own risk.
          </p>
          <p>
            Our website will normally be available for use 24 hours a day.
            However, we can’t guarantee that our website, or the information on
            our website, will always be available or uninterrupted. Please keep
            the software on your phone/device up to date including any
            anti-virus software when using our website.
          </p>
          <BoldText>3. ADVICE</BoldText>
          <p>
            Our website is designed to give you general information about our
            products and services. The content of our website is not financial,
            professional, legal or tax advice.
          </p>
          <BoldText>4. USING EXTERNAL LINKS</BoldText>
          <p>
            We’ve provided some links to external websites. If you use those
            links, you’ll leave our website to visit a third party website.
            We’re not responsible for the content or availability of those
            websites and if you decide to use any of the links, you’re doing so
            at your own risk.
          </p>
          <BoldText>5. INTELLECTUAL PROPERTY</BoldText>
          <p>
            All information, data and copyright material contained on this
            website, including any trade marks (whether officially registered of
            unregistered), trade names, brands and logos belong to us or to
            people who granted us permission to use this information, data
            and/or copyright material. You must not use any of this information
            unless you have written permission from us or the owner to do so.
          </p>
          <BoldText>6. MAKING CHANGES TO OUR WEBSITE</BoldText>
          <p>
            We may update our website and these Terms from time to time by
            amending this site. We may also decide to remove our website.
          </p>
          <BoldText>7. REGULATORY INFORMATION</BoldText>
          <p>
            NatWest are authorised by the Prudential Regulation Authority and
            regulated by the Financial Conduct Authority and the Prudential
            Regulation Authority. NatWest are registered on the Financial
            Services Register with registration number 121878.
          </p>
          <BoldText>8. THE LAW THAT APPLES TO THESE TERMS</BoldText>
          <p>
            English law applies to these Terms, unless you live in Scotland in
            which case it’s Scots law and if you ever need to take legal action
            against us you can do this in any UK court.
          </p>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default WebsiteTerms;
